import React, { useEffect, useMemo, useState } from "react";
import useMainService from "../../services/MainService";
import List from "../list/List";
import GameCardSkeleton from "../skeletons/GameCardSkeleton";
import { Pubg } from "../../images/backgrounds";
import {useTranslation} from "react-i18next";

const Games = (props) => {
    const { title, noButton, filter, render, listClass, items } = props;
    const { getAllProducts} = useMainService();

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const skeletons = Array(products.length || 6).fill(1);
    const { t } = useTranslation();

    useEffect(() => {
        if (!items) {
            setLoading(true);
            getAllProducts(filter)
                .then(val => { onListLoaded(val); })
                .catch(log => console.log(log));
        }
    }, []);

    const onListLoaded = (chars) => {
        setProducts(chars);
        setLoading(false);
    };

    const onListAdd = (chars) => {
        setProducts((products) => [...products, ...chars]);
        setLoading(false);
    };

    const renderFunc = (item, index) => {
        const images = item.images?.imagePaths?.[0] || item.images || Pubg;
        return render(index, item.name, items ? null : item.price, items ? null : item.priceWithDiscount, items ? item.id : item.gameId, images);
    };

    const children = useMemo(() => (loading ?
        skeletons.map((item, index) => (<GameCardSkeleton key={index} />))
        :
        items ? items.map(renderFunc) : products.map(renderFunc)), [loading, products, items]);

    return (
        <section className="games" id="new-games">
            <div className="games__row">
                <div className="games__nav nav-line ">
                    {/*Надпись Новое поступление корзине. включить после исправления сумм*/}
                    <div className="nav-line__text">{t(title)}</div>
                </div>
                <List containerClass={"games__list"} childClass={listClass ? listClass : "games__list__row"}>
                    {children}
                </List>
            </div>
        </section>
    );
}

export default Games;