import EldenRing from "../images/backgrounds/elden-ring-game-card.webp";
import GameForm from "../components/gameForm/GameForm";
import "../scss/catalog.scss";
import CategoriesList from "../components/categoriesList/CategoriesList";
import {useLocation} from "react-router-dom";
import useMainService from "../services/MainService";
import {useEffect, useMemo, useState} from "react";
import {Pubg} from "../images/backgrounds";
import {useTranslation} from "react-i18next";
import {RotatingLines} from "react-loader-spinner";

const Services = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const name = searchParams.get("name");
    const category = searchParams.get("categoryname");

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortState, setSortState] = useState(0);

    const {getAllGames, getAllGamesByName} = useMainService();
    const {t} = useTranslation();

    const allowedNames = [
        "EA Gift Card ",
        "Nintendo eShop Card - US",
        "PSN Card United States",
        "Xbox Live Gift Card",
        "USD Razer Gold Global Pin",
        "Battlenet Gift Card Balance - (USA)",
        "NCSOFT",
        "Nintendo eShop Prepaid Card-PLN",
        "Tango",
        "Nexon Game Card",
        "Google Play",
        "iTunes Gift Card",
        "Nintendo eShop Card - BR",
        "Netflix Gift Card",
        "Riot Points EU NORDIC/EAST",
        "Riot Access",
        "Steam Wallet Card",
    ];

    useEffect(() => {
        setLoading(true);
        const fetchProducts = async () => {
            try {
                const prods = name ? await getAllGamesByName(name) : await getAllGames();
                const filteredProducts = prods.filter(product => allowedNames.includes(product.name));
                onProductLoad(filteredProducts);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        };
        fetchProducts();
    }, [category, name, sortState, currentPage]);

    const onProductLoad = (prods) => {
        setLoading(false);
        setProducts(prods);
    };

    const getProductImage = (images) => images?.mainframe || EldenRing || Pubg;

    const children = useMemo(
        () =>
            products.map((item) => (
                <GameForm
                    key={item.id}
                    src={getProductImage(item.images)}
                    id={item.id}
                    name={item.name}
                    extraClass="videogames__item"
                    indicators={[]}
                />
            )),
        [products]
    );

    const content = loading ? (
        <div style={{margin: "0 auto"}}>
            <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
            />
        </div>
    ) : (
        children
    );

    return (
        <div className="main">
            <CategoriesList/>
            <div className="main__container">
                <div className="sides">
                    <div className="sides__row">
                        <div className="sides__container">
                            <div className="videogames">
                                <div className="videogames__row">{content}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
