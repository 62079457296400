import NavBottomItem from "./NavBottomItem";
import { HomeIcon, HomeIconHover } from "../../images/category";
import { Like, LikeHover, Cart, CartHover, Lines, LinesHover} from "../../images/system";
import {useTranslation} from "react-i18next";
import { ArcadeIcon, Steam} from "../../images/category/navbar";
import {useContext} from "react";
import {AuthContext} from "../../providers/AuthProvider";
import {PopupContext} from "../../providers/PopupProvider";
import {useNavigate} from "react-router-dom";


const NavBottom = () => {
    const context = useContext(AuthContext);
    const { isShow, setShow } = useContext(PopupContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const toCart = () => {
        if (context.isAuth) {
            navigate('/cart');
        } else {
            setShow(true);
        }
    }
    const toSteam = () => {
        if (context.isAuth) {
            navigate('/steam');
        } else {
            setShow(true);
        }
    }

    return (
        <section className="nav-bottom">
            <div className="nav-bottom__row">
                <NavBottomItem to={"/"} src={HomeIcon} srcHover={HomeIconHover} alt={"home"} text={t("main-link")} />
                <NavBottomItem to={"/favourites"} src={Like} srcHover={LikeHover} alt={"favourites link"} text={t("favourites")} />
                <NavBottomItem onClick={toCart} isCart src={Cart} srcHover={CartHover} alt={"cart link"} text={t("cart")} />
                <NavBottomItem to={"/categories"} src={ArcadeIcon} srcHover={ArcadeIcon} alt={"categories"} text={t("categories-link")} />
                <NavBottomItem onClick={toSteam} src={Steam} srcHover={Steam} alt={"steam"} text={t("steam-link")} />
                <NavBottomItem to={"/menu"} src={Lines} srcHover={LinesHover} alt={"menu link"} text={t("menu")} />
            </div>
        </section>
    );
}

export default NavBottom;