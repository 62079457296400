import Games from "../components/games/Games";
import CategoryItem from "../components/categoryItem/CategoryItem";
import "../scss/main.scss";
import CategoriesList from "../components/categoriesList/CategoriesList";
import { useEffect, useMemo, useState } from "react";
import useMainService from "../services/MainService";
import Genres from "../components/genres/Genres";
import GameForm from "../components/gameForm/GameForm";
import { useTranslation } from "react-i18next";
import SimpleSlider from "../components/slider/Slider";
import {YMInitializer} from "react-yandex-metrika";
import {RotatingLines} from "react-loader-spinner";


const Home = () => {
    const { getRecomendations } = useMainService();
    const [blocks, setBlocks] = useState([]);
    const [loadingState, setLoadingState] = useState(false);
    const { t } = useTranslation();
    const METRIKA_API_URL = 'https://api-metrika.yandex.net/stat/v1/data';
    const COUNTER_ID = '98162676'; //ID счетчика
    const OAUTH_TOKEN = 'fdc529e65a0d4c95a7319e4ce742f31e'; // токен OAuth
    const [visits, setVisits] = useState(null);

    useEffect(() => {
        // Функция для получения даты в формате YYYY-MM-DD
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы в JavaScript начинаются с 0
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        // Получаем текущую дату
        const endDate = new Date();
        // Вычисляем дату начала (1 месяц назад)
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);

        // Преобразуем даты в формат YYYY-MM-DD
        const date1 = formatDate(startDate);
        const date2 = formatDate(endDate);

        const fetchVisits = async () => {
            try {
                const response = await fetch(`${METRIKA_API_URL}?ids=${COUNTER_ID}&metrics=ym:s:visits&date1=${date1}&date2=${date2}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `OAuth ${OAUTH_TOKEN}`,
                    },
                    mode: 'no-cors'
                });

                if (!response.ok) {
                    throw new Error(`Ошибка HTTP: ${response.status}`);
                }

                const data = await response.json();
                const visitsData = data.totals[0];
                setVisits(visitsData);
            } catch (error) {
                console.error('Ошибка при получении данных Яндекс.Метрики:', error);
            }
        };

        fetchVisits();
    }, []);

    useEffect(() => {
        setLoadingState(true);
        getRecomendations()
            .then(data => {
                // Перемещаем популярные в начало
                const sortedData = data.sort((a, b) => {
                    if (a.name === "Slider") return -1; // Слайдер всегда первый
                    if (b.name === "Slider") return 1;
                    if (a.name === "popular") return -1; // Популярные после слайдера
                    if (b.name === "popular") return 1;
                    return 0;
                });
                setBlocks(sortedData);
                setLoadingState(false);
            })
            .catch(() => setLoadingState(false));
    }, []);

    useEffect(() => {
        // Отслеживание посещений страницы
        window.ym && window.ym(98162676, 'hit', window.location.pathname);
    }, []);

    const elements = useMemo(() => {
        return blocks.map((item, index) => {
            if (item.name === "Slider") return <SimpleSlider key={index} items={item.games} />;
            else return <Games key={index} title={item.name} items={item.games}
                render={(index, name, price, oldPrice, id, imagePath) =>
                    (<GameForm key={index} name={name} id={id} src={imagePath} indicators={[]} />)} >
            </Games>;
        })
    }, [blocks]);

    return (
        <div className="main">
            <YMInitializer accounts={[98162676]} options={{ webvisor: true }} />

            <CategoriesList />


            <div className="main__container">
                {loadingState ? (  // Показываем спиннер во время загрузки
                    <div style={{margin: "0 auto"}}>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                ) : (
                    elements
                )}
                <Genres title={t('categories')}
                    render={(name, id) =>
                        (<CategoryItem key={id} link={`/catalog?categoryname=${name}`} name={name} icon={id - 1} />)} >
                </Genres>

                {/*<div style={{color:"white"}}>*/}
                {/*    <h1>Количество посещений:</h1>*/}
                {/*    {visits !== null ? (*/}
                {/*        <p>{visits}</p>*/}
                {/*    ) : (*/}
                {/*        <p>Загрузка данных...</p>*/}
                {/*    )}*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default Home;