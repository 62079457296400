import "../scss/catalog.scss";
import { useTranslation } from "react-i18next";

const Guarantee = () => {
    const {t} = useTranslation();

    return (
        <>

        </>
    );
}

export default Guarantee;